<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
  <vxe-modal ref="xModal" title="详情" show-zoom width="1000" height="600">
    <template v-slot>
      <nucleateDetai :selectRow="selectRow" />
    </template>
  </vxe-modal>
</div>
</template>

<script>
import nucleateDetai from "./nucleateDetai.vue";
export default {
  components: {
    nucleateDetai
  },
  data() {
    return {
      selectRow: {},
      loading: false,
      currNode: {},
      formData: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    let { data } = this.formOptions;
                    data["parentId"] = this.currNode.id;
                    this.searchOptions.data['parentId'] = this.currNode.id;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "batchNo",
            title: "登记批次",
            width: 200,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择登记批次"
              },
            }
          },
          {
            field: "name",
            title: "登记名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入登记名称"
              }
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 200,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  name: "edit",
                  status: "primary",
                  label: "修改",
                  eventName: "queryEvent"
                },
                {
                  name: "detail",
                  status: "primary",
                  label: "查看详情",
                  events: {
                    click: (row) => {
                      this.selectRow = row;
                      this.$refs.xModal.open();
                    }
                  }
                },
                {
                  name: "delete",
                  status: "danger",
                  label: "删除",
                  eventName: "removeEvent"
                }
              ]
            }
          }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
          },
          {
            field: "name",
            title: "登记名称",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入登记名称"
              }
            }
          },
          {
            field: "batchNo",
            title: "登记批次",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请选择登记批次",
                type: "date",
              },
            }
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: "remark",
            title: "备注内容",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "120",
                resize: "none",
                placeholder: "请输入备注内容",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属小区" }],
          name: [{ required: true, message: "请输入登记名称" }],
          batchNo: [{ required: true, message: "请选择登记批次" }],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          name: "",
          batchNo: '',
          enabled: '1'
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "batchNo",
            title: "登记批次",
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择登记批次"
              },
            }
          },
          {
            field: "name",
            title: "登记名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入登记名称",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          name: "",
          batchNo: ""
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['parentId'] = node.id;
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped></style>
