<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" service="/xifang/nucleicRegisterDetail" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  props: {
    selectRow: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      formData: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          export: true,
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "buildingName",
            title: "楼号",
            minWidth: 80,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入楼号"
              },
            }
          },
          {
            field: "unitName",
            title: "单元编号",
            minWidth: 80,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入单元编号"
              },
            }
          },
          {
            field: "roomLayer",
            title: "楼层",
            minWidth: 80,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入楼层"
              },
            }
          },
          {
            field: "roomName",
            title: "房屋编号",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入房屋编号"
              },
            }
          },
          {
            field: "name",
            title: "住户姓名",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入住户姓名"
              }
            }
          },
          {
            field: "idCard",
            title: "身份证号",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入身份证号"
              }
            }
          },
          {
            field: "phone",
            title: "住户电话",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入住户电话"
              }
            }
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        items: [],
        rules: {},
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "roomCode",
            title: "房屋编号",
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入房屋编号",
              },
            }
          },
          {
            field: "residentName",
            title: "住户姓名",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入住户姓名",
              },
            },
          },

          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          registerId: this.selectRow.id
        },
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
